import theme from '@gameonsports/components/cjs/theme'
import React from 'react'

interface ThemeContextValues {
  background: keyof typeof theme | null
  setBackground: React.Dispatch<React.SetStateAction<keyof typeof theme | null>>
  hideSwishBackground: boolean | null
  setHideSwishBackground: React.Dispatch<React.SetStateAction<boolean | null>>
  isWebview: boolean
}

const ThemeContext = React.createContext<ThemeContextValues>({
  background: null,
  setBackground: () => null,
  hideSwishBackground: null,
  setHideSwishBackground: () => null,
  isWebview: false,
})

export default ThemeContext
