import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import Input from '@gameonsports/components/cjs/Input'
import { Field, FieldProps, FormikProps } from 'formik-1.5.8'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { media } from '../../utils/styled-components-utils'

const SearchContainer = styled.div`
  position: relative;
`

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 1.125rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
`

const SearchInput = styled(Input)`
  input {
    height: 4rem;
    padding-left: 3.5rem;
    padding-right: 1rem;
    border-color: ${props => props.theme.black400};
    border-radius: 9px;
    font-weight: 400;
    appearance: none;
  }

  ${media.desktop`
    input {
      padding-right: 8rem;
    }
  `}
`

const SearchButton = styled(Button)`
  display: none;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  border-radius: 3px;
  outline: none;
  margin: 0;

  ${media.desktop`
    display: flex;
  `}
`

export const SearchFormValidationSchema = Yup.object().shape({
  query: Yup.string()
    .test(
      'length',
      'Search query must be at least 3 characters',
      value => !value || value.replace(/ /g, '').length >= 3,
    )
    .required('Search query is required'),
})

export interface SearchFormValues {
  query: string
}

interface SearchFormProps {
  className?: string
}

const SearchForm: React.FC<FormikProps<SearchFormValues> & SearchFormProps> = ({
  touched,
  isValid,
}) => (
  <Field name="query">
    {({ field }: FieldProps) => (
      <SearchContainer>
        <SearchIcon name="search" />
        <SearchInput
          {...field}
          id={field.name}
          type="search"
          touched={touched.query}
          placeholder="Search associations and clubs"
          label="Search associations and clubs"
          hideError
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          hideLabel
          preventAutoComplete
        />
        <SearchButton type="submit" disabled={!isValid}>
          Search
        </SearchButton>
      </SearchContainer>
    )}
  </Field>
)

export default SearchForm
