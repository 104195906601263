import { publicEnv } from '../constants/publicEnv'
import { isSSR } from './ssr'

export const getEnvironment = () => {
  //TODO: This is technically incorrect, since just because we're in SSR doesn't mean we're not in an environment.
  // Can't safely/easily remove since some code looks like it makes this assumption, eg gtm

  if (isSSR) {
    return undefined
  }

  if (!!publicEnv.REACT_APP_ENVIRONMENT) {
    return publicEnv.REACT_APP_ENVIRONMENT
  }

  //TODO: Once the chart change is in all environments the logic below can be cleaned up.
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return 'local'
  }

  const href = window.location.href

  return href.includes('dev.')
    ? 'dev'
    : href.includes('qa.')
    ? 'qa'
    : href.includes('uat.')
    ? 'uat'
    : href.includes('toblerones.')
    ? 'toblerones'
    : href.includes('thesharkeys.')
    ? 'thesharkeys'
    : href.includes('jatz.')
    ? 'jatz'
    : href.includes('perftest.')
    ? 'perftest'
    : href.includes('sit.')
    ? 'sit'
    : 'production'
}
