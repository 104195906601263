import React from 'react'

interface UnconfirmedEmailContextValues {
  email: string | null
  setEmail: (email: string) => void
}

const UnconfirmedEmailContext =
  React.createContext<UnconfirmedEmailContextValues>({
    email: null,
    setEmail: () => null,
  })

export default UnconfirmedEmailContext
