import { Attributes } from '@splitsoftware/splitio/types/splitio'
import murmur from 'murmurhash-js'
import { useContext, useEffect, useMemo } from 'react'
import AccountContext from '../contexts/AccountContext/AccountContext'
import { TenantContext } from '../contexts/TenantContext/TenantContext'
import { FeatureFlag } from '../flags'
import {
  featureEnabledWithConfig,
  setupAuthedClient,
  splitUserKey,
} from '../splitClient'

const numberOfBuckets = 10000

export default (name: FeatureFlag, attributes: Attributes = {}) => {
  const { tenant } = useContext(TenantContext)
  const { userSub } = useContext(AccountContext)

  // This is the user sub (cognito id) which has been hashed into a number between 0 and 9999
  // Required so that we can traffic split users for feature flags without exceeding our quota
  // of 50000 monthly tracked keys (MTK) in Split.io
  const hashedUserSub = useMemo(
    () =>
      userSub ? (murmur.murmur3(userSub) % numberOfBuckets).toString() : null,
    [userSub],
  )

  const allAttributes = tenant ? { tenant, ...attributes } : attributes

  useEffect(() => {
    if (hashedUserSub && splitUserKey !== hashedUserSub) {
      setupAuthedClient(hashedUserSub)
    }
  }, [hashedUserSub])

  const treatmentWithConfig = featureEnabledWithConfig(
    name,
    allAttributes,
    hashedUserSub ?? undefined,
  )

  return [treatmentWithConfig.treatment, treatmentWithConfig.config]
}
