import React from 'react'
import styled from 'styled-components'
import bgLarge from './background-lg.jpg'
import bgSmall from './background-sm.jpg'

// Not using props and theme in here as the ErrorBoundary wraps the whole app and wont have access to the theme
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url(${bgSmall});
  background-size: cover;
  color: black;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 75rem) {
    background-image: url(${bgLarge});
  }
`

const Heading = styled.h1`
  font-size: 8rem;
  margin: 0 0 1rem;
`

const P = styled.p`
  font-size: 2rem;
`

interface ErrorPageProps {
  message?: string
}

const ErrorPage: React.FC<ErrorPageProps> = () => (
  <Container data-testid="error-page">
    <Heading>500</Heading>
    <P>Looks like we've gone out of bounds</P>
  </Container>
)

export default ErrorPage
