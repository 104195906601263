import { AuthOptions } from '@aws-amplify/auth/lib-esm/types/Auth'
import { publicEnv } from './publicEnv'

export const authConfig: AuthOptions = {
  userPoolWebClientId: publicEnv.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  userPoolId: publicEnv.REACT_APP_AMPLIFY_USER_POOL_ID,
  oauth:
    publicEnv.REACT_APP_OAUTH_REDIRECT_SIGN_IN &&
    publicEnv.REACT_APP_OAUTH_REDIRECT_SIGN_OUT &&
    publicEnv.REACT_APP_OAUTH_DOMAIN
      ? {
          domain: publicEnv.REACT_APP_OAUTH_DOMAIN,
          scope: [
            'email',
            'openid',
            'phone',
            'profile',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: publicEnv.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
          redirectSignOut: publicEnv.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
          responseType: 'code',
        }
      : undefined,
}
