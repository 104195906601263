import Loader from '@gameonsports/components/cjs/Loader'
import React from 'react'
import styled from 'styled-components'

const Block = styled.section`
  max-width: 62rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 4rem auto 0;
  width: 100%;
`

const LoaderContainer = styled.section`
  position: relative;
  width: 6rem;
  margin: 0 auto;
`

const PageLoader: React.FC = () => {
  return (
    <Block>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </Block>
  )
}

export default PageLoader
