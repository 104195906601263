/// <reference types="webpack-env" />
import 'polyfill-object.fromentries'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './i18n'
import { datadogRum } from '@datadog/browser-rum'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-react'
import { loadableReady } from '@loadable/component'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import packageJson from '../package.json'
import AppProviders from './components/AppProviders'
import { publicEnv } from './constants/publicEnv'
import App from './pages/App'
import { splitClient } from './splitClient'
import { getEnvironment } from './utils/env'
import { isSSR } from './utils/ssr'

if (!isSSR && window.location.hostname !== 'localhost') {
  const hostname = window.location.hostname
  const environment = getEnvironment()

  if (environment === 'production') {
    // GTM container: www.playhq.com
    TagManager.initialize({
      gtmId: 'GTM-NJSLDXL',
    })
  } else {
    // GTM container: www.playhq.com - NON_PROD
    TagManager.initialize({
      gtmId: 'GTM-P7H2DT3X',
    })
  }

  const instrumentations = [...getWebInstrumentations()]

  // Importing `@grafana/faro-web-tracing` causes Grafana to complain about unhandled
  // promises even if they are wrapped in a try catch, causing excessive amount of errors logged
  // Ref: https://github.com/grafana/faro-web-sdk/issues/219
  //
  // if (publicEnv.REACT_APP_GRAFANA_FARO_TRACING_ENABLED === 'true') {
  //   const endpoints: string[] = [
  //     publicEnv.REACT_APP_GRAPH_ENDPOINT,
  //     publicEnv.REACT_APP_SEARCH_ENDPOINT,
  //     publicEnv.REACT_APP_SPECTATOR_WS_ENDPOINT,
  //     publicEnv.REACT_APP_SPECTATOR_ENDPOINT,
  //   ].filter(nonNullable) as string[]

  //   instrumentations.push(
  //     new TracingInstrumentation({
  //       instrumentationOptions: {
  //         propagateTraceHeaderCorsUrls: endpoints,
  //       },
  //     }),
  //   )
  // }

  if (publicEnv.REACT_APP_GRAFANA_FARO_URL) {
    initializeFaro({
      url: publicEnv.REACT_APP_GRAFANA_FARO_URL,
      app: {
        name: 'layup',
        environment,
        release: process.env.CODEBUILD_RESOLVED_SOURCE_VERSION,
      },
      instrumentations: instrumentations,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'webkit-masked-url:',
        'Failed to get ServiceWorkerRegistration objects:',
      ],
    })
  }

  if (
    publicEnv.REACT_APP_DATADOG_APPLICATION_ID &&
    publicEnv.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: publicEnv.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: publicEnv.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      allowedTracingOrigins: [
        publicEnv.REACT_APP_GRAPH_ENDPOINT!.replace('/graphql', ''),
        publicEnv.REACT_APP_SEARCH_ENDPOINT!.replace('/graphql', ''),
        publicEnv.REACT_APP_SPECTATOR_ENDPOINT!.replace('/graphql', ''),
      ],
      service: 'layup',
      env:
        hostname.match(/^([\w]+)\.([\w]+)\.playhq\.com$/)?.[2] ?? 'production',
      version: packageJson.version,
      premiumSampleRate: 0,
      replaySampleRate: 0,
      sampleRate: 10,
      tracingSampleRate: 10,
      trackInteractions: true,
    })
  }
}

export const bootstrap = async (
  component: React.ReactElement<any>,
  elId = 'root',
) => {
  loadableReady(() => {
    ReactDOM.render(
      // ! Commented out because of https://github.com/styled-components/styled-components/issues/3076
      // ! Once this is fixed we can re-add this
      // <React.StrictMode>
      <AppProviders>{component}</AppProviders>,
      // </React.StrictMode>,
      document.getElementById(elId),
    )
  })
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCKS === 'true'
) {
  import('./mocks/dev-server').then(({ server }) =>
    server
      .start({
        onUnhandledRequest: 'bypass',
      })
      .then(() => {
        bootstrap(<App />)
      }),
  )
} else if (process.env.NODE_ENV !== 'test') {
  new Promise(res => {
    splitClient.on(splitClient.Event.SDK_READY, res)
    splitClient.on(splitClient.Event.SDK_READY_FROM_CACHE, res)
    splitClient.on(splitClient.Event.SDK_READY_TIMED_OUT, res)
  }).then(() => {
    bootstrap(<App />)
  })
}

// We don't want to unregister the service worker in development as it can terminate MSW
if (process.env.NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}
