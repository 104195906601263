import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const i18nNamespaces = [
  'common',
  'basketball',
  'afl',
  'cricket',
  'netball',
  'hockey',
]

export const i18nOptions: InitOptions = {
  ns: i18nNamespaces,
  defaultNS: 'common',
  fallbackNS: 'common',
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en'],
  load: 'languageOnly',
  debug: process.env.NODE_ENV === 'development',
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  backend: {
    loadPath: '/static/locales/{{lng}}/{{ns}}.json',
  },
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(i18nOptions)

export default i18n
