import React from 'react'
import styled from 'styled-components'

const BlockInner = styled.div`
  max-width: 62rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  width: 100%;
`

const FullWidthBlock: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <BlockInner className={className}>{children}</BlockInner>

export default FullWidthBlock
