import React from 'react'
import { CurrentAccountQuery } from '../../generated/graphql'

interface AccountContextValues {
  user: CurrentAccountQuery['account'] | null
  userSub?: string | null
}

const AccountContext = React.createContext<AccountContextValues>({
  user: null,
  userSub: null,
})

export default AccountContext
