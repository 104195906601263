import {
  AFL_REGISTRATION_EXTERNAL_ID,
  AFL_REGISTRATION_PATH,
  CA_REGISTRATION_EXTERNAL_ID,
  CA_REGISTRATION_PATH,
} from '../constants/registrationPaths'
import {
  GetRegistrationRegistrationSettings,
  GetRegistrationSeason,
  GetRegistration_Season,
  MembershipApplicationPaymentTypes,
  RegistrationOverviewRegistrationSettings,
  RegistrationOverviewSeason,
  RegistrationOverview_Season,
} from '../generated/graphql'

export const getEventOrganisationName = (
  season:
    | GetRegistrationSeason
    | GetRegistration_Season
    | RegistrationOverviewSeason
    | RegistrationOverview_Season,
) => {
  if (season.__typename === 'DiscoverSeason') {
    return season.competition.organisation.name
  }

  if (season.__typename === 'ProgramSeason') {
    return season.program.name
  }

  return ''
}

export const getOrganisationName = (
  registrationSettings:
    | GetRegistrationRegistrationSettings
    | RegistrationOverviewRegistrationSettings,
) => {
  if (registrationSettings.__typename === 'DiscoverRegistration') {
    return registrationSettings.organisation.name
  }

  return getEventOrganisationName(registrationSettings.season)
}

export const getEventName = (
  season:
    | GetRegistrationSeason
    | GetRegistration_Season
    | RegistrationOverviewSeason
    | RegistrationOverview_Season,
) => {
  if (season.__typename === 'DiscoverSeason') {
    return season.competition.name
  }

  if (season.__typename === 'ProgramSeason') {
    return [season.program.name, season.program.alias]
      .filter(Boolean)
      .join(' - ')
  }

  return ''
}

export const getMembershipPaymentTypeLabel = (
  type: MembershipApplicationPaymentTypes,
) =>
  ({
    [MembershipApplicationPaymentTypes.ThreeMonths]: '3 month',
    [MembershipApplicationPaymentTypes.SixMonths]: '6 month',
    [MembershipApplicationPaymentTypes.NineMonths]: '9 month',
    [MembershipApplicationPaymentTypes.TwelveMonths]: '12 month',
  }[type] || '')

export const getExternalIDProviderName = (tenant?: string) =>
  tenant === 'afl' ? 'AFL iD' : 'Cricket ID'

export const getExternalIDProviderErrorPrefix = (tenant?: string) =>
  tenant === 'afl' ? 'AFL_ACCOUNT_ERROR' : 'CA_ACCOUNT_ERROR'

export const getExternalIDLocalStoragePath = (tenant?: string) =>
  tenant === 'afl' ? AFL_REGISTRATION_EXTERNAL_ID : CA_REGISTRATION_EXTERNAL_ID

export const getExternalIDRegCodeStoragePath = (tenant?: string) =>
  tenant === 'afl' ? AFL_REGISTRATION_PATH : CA_REGISTRATION_PATH
