/**
 * ssrForceFetchDelay breaks graphql subscription functionality above 3.4.16
 * subscribeToMore delay is introduced to wait for delay to complete
 * https://github.com/apollographql/apollo-client/issues/9433
 */
export const SSR_FORCE_FETCH_DELAY = 100

/**
 * value of SUBSCRIBE_TO_MORE_DELAY = SSR_FORCE_FETCH_DELAY + 100 still caused
 * the subscription to stop. 1000ms is a reasonable amount of time as live scoring
 * update delays are on average a few seconds before they get pushed
 */
export const SUBSCRIBE_TO_MORE_DELAY = SSR_FORCE_FETCH_DELAY + 1000
