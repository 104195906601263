import useAuthentication from '@gameonsports/aws-amplify-hooks/cjs/useAuthentication'
import { faro } from '@grafana/faro-react'
import React from 'react'
import { publicEnv } from '../../constants/publicEnv'
import { useCurrentAccountQuery } from '../../generated/graphql'
import AccountContext from '../AccountContext'

const AccountProvider: React.FC = React.memo(({ children }) => {
  const { authenticated, userSub } = useAuthentication()
  const { data } = useCurrentAccountQuery({
    skip: !authenticated,
    ssr: false,
    onError: () => null,
    fetchPolicy: 'cache-and-network',
  })

  const account = data && data.account ? data.account : null

  if (account && publicEnv.REACT_APP_GRAFANA_FARO_URL) {
    faro.api.setUser({
      id: account.id,
    })
  }

  return (
    <AccountContext.Provider value={{ user: account, userSub }}>
      {children}
    </AccountContext.Provider>
  )
})

export default AccountProvider
