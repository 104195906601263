import { Box } from '@gameonsports/components/cjs/Box'
import Button from '@gameonsports/components/cjs/Button'
import Logo from '@gameonsports/components/cjs/Logo'
import { Stack } from '@gameonsports/components/cjs/Stack'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { Link, LinkProps } from '@reach/router'
import React, { useContext } from 'react'
import styled from 'styled-components'
import AccountContext from '../../contexts/AccountContext'
import ThemeContext from '../../contexts/ThemeContext/ThemeContext'
import { media } from '../../utils/styled-components-utils'
import FullWidthBlock from '../FullWidthBlock'

const FooterLink = styled(Text)<LinkProps<{}> | React.AnchorHTMLAttributes<{}>>`
  text-decoration: none;
  color: currentColor;
  transition: color 150ms ${props => props.theme.easeInOutCirc};

  &:hover,
  &:focus {
    color: ${props => props.theme.blueberry400};
  }
`

const StyledLogo = styled(Logo)`
  width: 70px;
`

const FooterDetails = styled(FullWidthBlock)`
  display: grid;
  grid-gap: 2rem;
  padding: 2rem 1rem;

  ${media.tablet`
    padding-top: 7rem;
    padding-bottom: 7rem;
    grid-gap: 5rem;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const CallToActions = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }

  @supports (display: grid) {
    display: grid;
    align-items: start;
    grid-gap: 1rem 0.5rem;
    grid-template-areas: 'logo logo' 'login signup';

    > * + * {
      margin-left: initial;
    }
  }

  .logo-link {
    grid-area: logo;
  }

  .login-link {
    grid-area: login;
  }

  .signup-link {
    grid-area: signup;
  }

  ${media.tablet`
    grid-gap: 0.5rem;
  `}
`

const GlobalFooter: React.FC = () => {
  const { user } = useContext(AccountContext)
  const { isWebview } = useContext(ThemeContext)
  const year = new Date().getFullYear()

  if (isWebview) return null

  return (
    <Box color="white400" backgroundColor="black400" marginTop="auto">
      <FooterDetails>
        <CallToActions>
          <Link to="/" data-testid="footer-logo-link" className="logo-link">
            <StyledLogo inverted />
          </Link>
          {!user && (
            <>
              <Button
                as={Link}
                to="/login"
                data-testid="footer-login-link"
                variant="white"
                className="login-link"
                halo
                size="small"
              >
                Log in
              </Button>
              <Button
                as={Link}
                to="/signup"
                data-testid="footer-signup-link"
                className="signup-link"
                variant="white"
                size="small"
              >
                Sign up
              </Button>
            </>
          )}
        </CallToActions>
        <Stack
          as="ul"
          listStyleType="none"
          gap="m"
          direction={['row', 'row', 'column']}
          flexWrap="wrap"
        >
          <li>
            <FooterLink as={Link} to="/">
              Discover
            </FooterLink>
          </li>
          <li>
            <FooterLink as="a" href="/au/" rel="noopener">
              For Organisations
            </FooterLink>
          </li>
          <li>
            <FooterLink as="a" href="/au/about-us" rel="noopener">
              About Us
            </FooterLink>
          </li>
          <li>
            <FooterLink
              as="a"
              target="_blank"
              href="https://support.playhq.com"
              rel="noopener"
            >
              Support
            </FooterLink>
          </li>
          <li>
            <FooterLink as="a" href="/au/contact" rel="noopener">
              Contact Us
            </FooterLink>
          </li>
        </Stack>
      </FooterDetails>
      <Box
        paddingX="m"
        paddingY={['l', null, 'm']}
        borderColor="blackberry400"
        borderTopWidth="thin"
        borderTopStyle="solid"
      >
        <Stack
          gap="s"
          direction={['column', 'column', 'row']}
          justifyContent="space-between"
          maxWidth="62rem"
          marginX="auto"
        >
          <Stack
            gap={['s', 's', 'm']}
            direction={['column', 'column', 'row']}
            color="grey400"
          >
            <FooterLink as={Link} to="/terms" className="link-hover" size="14">
              Terms of Use
            </FooterLink>
            <FooterLink
              as={Link}
              to="/privacy"
              className="link-hover"
              size="14"
            >
              Privacy Policy
            </FooterLink>
          </Stack>
          <Text size="14" color="grey400">
            &copy; {year} PlayHQ. All rights reserved. Made in Melbourne,
            Australia.
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}

export default GlobalFooter
