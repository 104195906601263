import React from 'react'

interface TenantContextValues {
  tenant?: string
}

export const TenantContext = React.createContext<TenantContextValues>({
  tenant: undefined,
})

export const TenantProvider = ({
  tenant,
  children,
}: {
  tenant: string | undefined
  children?: React.ReactNode
}) => {
  return (
    <TenantContext.Provider value={{ tenant }}>
      {children}
    </TenantContext.Provider>
  )
}
