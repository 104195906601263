import { WindowLocation } from '@reach/router'

export interface LocationParams {
  [key: string]: string | undefined
}

export const getLocationParams: (
  location: Pick<WindowLocation | Location, 'search'>,
) => LocationParams = location => {
  const params = location.search.slice(1).split('&')

  return params.reduce((paramsObj: LocationParams, param: string) => {
    const separatorIndex = param.indexOf('=')
    const currentParam = {
      name: param.slice(0, separatorIndex),
      value: param.slice(separatorIndex + 1),
    }

    paramsObj[currentParam.name] = currentParam.value
    return paramsObj
  }, {} as LocationParams)
}
