export const CA_REGISTRATION_PATH = 'registrationCode'
export const CA_REGISTRATION_EXTERNAL_ID = 'registrationExternalId'

export const AFL_REGISTRATION_PATH = 'aflRegistrationCode'
export const AFL_REGISTRATION_EXTERNAL_ID = 'aflRegistrationExternalId'
export const REGISTRATION_PAYMENT_CALLBACK = 'registrationPaymentCallback'
export const EXTERNAL_LOGIN_PROVIDER_CALLBACK = 'externalLoginProviderCallback'
export const REGISTRATION_PAYMENT_VALUES = 'registrationPaymentValues'
export const REGISTRATION_PAYMENT_EXTERNAL_VOUCHER_VALUES =
  'registrationPaymentExternalVoucherValues'
export const REGISTRATION_PAYMENT_VOUCHER_VALUES =
  'registrationPaymentVoucherValues'
export const REGISTRATION_PAYMENT_SESSION = 'registrationPaymentSession'
export const REGISTRATION_PAYMENT_ORDER_ID = 'registrationPaymentOrderID'
