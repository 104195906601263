import Notification from '@gameonsports/components/cjs/Notification'
import React from 'react'

interface State {
  error: Error | undefined
}

class PageLoadError extends React.Component<{}, State> {
  state: State = {
    error: undefined,
  }

  componentDidCatch(error: Error) {
    if (error.message && error.message.includes('Loading chunk')) {
      this.setState({ error })
      return
    }

    // Bubble the error up
    throw error
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return error === undefined ? (
      children
    ) : (
      <div data-testid="page-load-error">
        <Notification variant="error">
          Something went wrong.{' '}
          <a href="#" onClick={window.location.reload}>
            Please try again.
          </a>
        </Notification>
      </div>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

export default PageLoadError
