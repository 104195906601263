import { useToggle } from '@gameonsports/components/cjs/_hooks/useToggle'
import { Box } from '@gameonsports/components/cjs/Box'
import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import Logo from '@gameonsports/components/cjs/Logo'
import Notification from '@gameonsports/components/cjs/Notification'
import { Stack } from '@gameonsports/components/cjs/Stack'
import { Text } from '@gameonsports/components/cjs/Text'
import VisuallyHidden from '@gameonsports/components/cjs/VisuallyHidden'
import { Link, LinkProps, WindowLocation, navigate } from '@reach/router'
import { Formik } from 'formik-1.5.8'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import AccountContext from '../../contexts/AccountContext'
import ThemeContext from '../../contexts/ThemeContext/ThemeContext'
import SearchForm, {
  SearchFormValidationSchema,
  SearchFormValues,
} from '../../forms/SearchForm'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import { getLocationParams } from '../../utils/location'
import { media } from '../../utils/styled-components-utils'

const Container = styled.header<{ hasDropShadow?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-self: start;
  background-color: ${props => props.theme.blackberry400};
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.blackberry400};
  z-index: 4;
  ${props =>
    props.hasDropShadow &&
    css`
      box-shadow: 0 0 0 9999rem rgba(0, 0, 0, 0.4);
    `}

  .menu {
    display: none;

    ${media.tablet`
      display: flex;
    `}
  }

  .menuCheck:checked ~ .menu {
    display: flex;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;

  ${media.tablet`
    justify-content: initial;
  `}
`

const StyledLogo = styled(Logo)`
  width: 83px;
  height: 37px;
`

const AccountContainer = styled.div`
  position: relative;

  ${media.tablet`
    margin-left: 1rem;
  `}
`

const AccountDropdown = styled.nav`
  position: absolute;
  top: 100%;
  left: 0;
  width: 11rem;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  background: ${props => props.theme.white400};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  ${media.tablet`
    right: 0;
    left: initial;
  `}
`

const DropdownNavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const DropdownNavLink = styled(Text).attrs({
  size: '14',
  weight: '500',
  color: 'black400',
  as: Link,
})<LinkProps<{}>>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
  outline: none;
  transition: color 150ms ${props => props.theme.easeInOutCirc};

  svg {
    transition: fill 150ms ${props => props.theme.easeInOutCirc};
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.blueberry400};

    svg {
      fill: ${props => props.theme.blueberry400};
    }
  }
`

const AccountButton = styled.button`
  display: flex;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: transform 150ms ${props => props.theme.easeInOutCirc};

  &:hover,
  &:focus {
    transform: translateY(-2px);
  }
`

const Account = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  color: ${props => props.theme.white400};

  @supports (display: grid) {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
  }

  &::after {
    position: absolute;
    top: calc(50% + 2px);
    right: 0;
    transform: translateY(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.white400};

    ${props =>
      props.isOpen &&
      css`
        transform: translateY(-50%) rotateX(180deg);
      `}
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;

  @supports (display: grid) {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }

  ${media.tablet`
    grid-gap: 1rem;

    .login-button {
      margin-left: 1rem;
    }

    .signup-button {
      /* IE doesn't like display: initial, so setting display inline */
      display: inline;
      display: initial;
    }
  `}
`

const AliasBadge = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.25rem;
  }
`

const LeftContainer = styled.div`
  display: flex;
  justify-self: center;
  align-items: center;
  position: relative;
  z-index: 2;
  flex: 1;
  justify-content: space-between;

  ${media.tablet`
    justify-content: flex-start;
  `}
`

const SearchButton = styled(Button)`
  background-color: rgba(255, 255, 255, 0.2);
  border-color: transparent;
  outline: none;
  margin-left: 1.75rem;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.white400};
    color: ${props => props.theme.blackberry400};
    border-color: transparent;
  }
`

const slideIn = keyframes`
  0% {
    transform: translateY(-0.5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const Form = styled.form`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.white400};
  padding: 1rem;
  z-index: 999;
  animation: ${slideIn} 350ms ease;

  ${media.desktop`
    padding: 1rem 0;
  `}
`

const FormContainer = styled.div`
  width: 100%;
  margin: 0;

  ${media.tablet`
    max-width: 60rem;
  `}
`

const StyledNotification = styled(Notification)`
  max-height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  justify-content: center;
  font-weight: 600;

  & > div {
    height: 1rem;
    width: 1rem;
    background-color: ${props => props.theme.black400};
  }
`

interface GlobalHeaderProps {
  hideSearch?: boolean
  location: WindowLocation
}

const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  hideSearch,
  location,
}) => {
  const { user } = useContext(AccountContext)
  const { isWebview } = useContext(ThemeContext)
  const [accountOpen, toggleAccountOpen] = useToggle(false)
  const [searchOpen, toggleSearchOpen] = useToggle(false)
  const isEnvironmentLabelsOn = useFeatureFlagOn('layup-environment-labels')
  const [environment, setEnvironment] = useState<string | null>(null)

  const accountButtonRef = React.createRef<HTMLButtonElement>()
  const accountDropdownRef = React.createRef<HTMLDivElement>()
  const searchRef = React.createRef<HTMLFormElement>()
  const searchButtonRef = React.createRef<
    HTMLButtonElement & HTMLAnchorElement
  >()
  const mobileSearchButtonRef = React.createRef<HTMLButtonElement>()

  const params = location && location.search ? getLocationParams(location) : {}
  const query = params.query ? decodeURIComponent(params.query) : ''

  const closeAccountDropdownOnClickOutside = (
    event: MouseEvent,
    modal: React.RefObject<HTMLDivElement>,
    button: React.RefObject<HTMLButtonElement>,
  ) => {
    if (
      modal &&
      modal.current &&
      !modal.current.contains(event.target as Node) &&
      button &&
      button.current &&
      !button.current.contains(event.target as Node) &&
      accountOpen
    ) {
      toggleAccountOpen()
    }
  }

  const closeSearchOnClickOutside = (
    event: MouseEvent,
    search: React.RefObject<HTMLFormElement>,
    searchButton: React.RefObject<any>,
    mobileSearchButton: React.RefObject<HTMLButtonElement>,
  ) => {
    if (
      search &&
      search.current &&
      !search.current.contains(event.target as Node) &&
      (!searchButton ||
        !searchButton.current ||
        !searchButton.current.contains(event.target as Node)) &&
      (!mobileSearchButton ||
        !mobileSearchButton.current ||
        !mobileSearchButton.current.contains(event.target as Node)) &&
      searchOpen
    ) {
      toggleSearchOpen()
    }
  }

  const handleClick = (e: MouseEvent) => {
    closeAccountDropdownOnClickOutside(e, accountDropdownRef, accountButtonRef)
    closeSearchOnClickOutside(
      e,
      searchRef,
      searchButtonRef,
      mobileSearchButtonRef,
    )
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => document.removeEventListener('mousedown', handleClick, false)
  })

  useEffect(() => {
    if (accountOpen) {
      toggleAccountOpen()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if ('scrollTo' in window) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
  }, [location.pathname])

  const [isCheckboxChecked, setCheckboxChecked] = useState(false)

  const menuToggleRef = useRef<HTMLInputElement>(null)

  const close = () => {
    if (menuToggleRef.current?.checked) {
      menuToggleRef.current.checked = false
      setCheckboxChecked(false)
    }
  }

  useEffect(close, [location.pathname])

  // Set the environment name
  // useEffect does not run in SSR
  useEffect(() => {
    // eg. www.[env].playhq.com
    // Index 0 is 'www'
    // Index 1 is '[env]'
    let hostname = window.location.hostname.split('.')[1] ?? ''

    // No [env] in the domain indicates this is running in production, so we
    // should *not* show the notification.
    if (hostname === 'playhq') {
      setEnvironment(null)
      return
    }

    // The sharkeys environment is 'thesharkeys', ie. 'bv.thesharkeys.playhq.com',
    // we need to remove 'the' prefix here, as none of the other squads have this.
    if (hostname === 'thesharkeys') {
      hostname = hostname.slice(3)
    }

    // Show the environment variable as upper case for qa and uat, and title case
    // for squad environments, ie
    // - UAT / QA
    // - Sharkeys / Toblerones
    const prettyEnvironment = ['qa', 'uat'].includes(hostname)
      ? hostname.toUpperCase()
      : hostname.charAt(0).toUpperCase() + hostname.slice(1)

    setEnvironment(prettyEnvironment)
  }, [environment])

  if (isWebview) return null

  return (
    <>
      <Container hasDropShadow={searchOpen}>
        <Stack maxWidth="62rem" width="100%" paddingX="m" gap="l">
          {isEnvironmentLabelsOn && environment !== null && (
            <StyledNotification variant="info" data-testid="environment-label">
              {environment}
            </StyledNotification>
          )}
          <Box
            display="flex"
            gap="m"
            alignItems="center"
            justifyContent={['space-between', 'space-between', 'flex-start']}
          >
            <LeftContainer>
              <LogoLink to="/">
                <StyledLogo inverted width={83} height={37} />
              </LogoLink>

              {!hideSearch && (
                <SearchButton
                  onClick={() => toggleSearchOpen()}
                  data-testid="global-search-link"
                  variant="tertiary"
                  icon="search"
                  size="small"
                  ref={searchButtonRef}
                >
                  Search
                </SearchButton>
              )}
            </LeftContainer>

            <VisuallyHidden
              ref={menuToggleRef}
              as="input"
              type="checkbox"
              id="menuCheck"
              className="menuCheck"
              defaultChecked={isCheckboxChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCheckboxChecked(e.currentTarget.checked)
              }
            />
            <Text
              as="label"
              color="white400"
              // @ts-expect-error
              htmlFor="menuCheck"
              cursor="pointer"
              display={[null, null, 'none']}
              zIndex={10}
            >
              {isCheckboxChecked ? (
                <Icon name="cross" color="white400" />
              ) : (
                <Icon name="menu" color="white400" />
              )}
            </Text>

            <Stack
              gap={['xl', 'xl', 'm']}
              direction={['column', 'column', 'row']}
              className="menu"
              position={['fixed', null, 'static']}
              top="0"
              left="0"
              bottom="0"
              right="0"
              backgroundColor={['grape400', null, 'transparent']}
              justifyContent="center"
              padding={['m', null, 'none']}
            >
              <Stack
                as="ul"
                direction={['column', 'column', 'row']}
                listStyleType="none"
                gap={['l', 'l', 'm']}
                alignItems={[null, null, 'center']}
              >
                <li>
                  <Text
                    as={Link}
                    size={['32', null, '16']}
                    textDecoration="none"
                    // @ts-expect-error
                    to="/"
                    color="white400"
                    borderBottomWidth="2px"
                    borderColor="blueberry400"
                    borderBottomStyle="solid"
                    paddingY="s"
                  >
                    Discover
                  </Text>
                </li>
                <li>
                  <Text
                    as="a"
                    size={['32', null, '16']}
                    color="white400"
                    textDecoration="none"
                    // @ts-expect-error
                    href="/au/"
                    paddingY="s"
                  >
                    For Organisations
                  </Text>
                </li>
                <li>
                  <Text
                    as="a"
                    size={['32', null, '16']}
                    color="white400"
                    textDecoration="none"
                    // @ts-expect-error
                    href="/au/about-us"
                    paddingY="s"
                  >
                    About Us
                  </Text>
                </li>
              </Stack>
              {user ? (
                <ButtonContainer>
                  <AccountContainer>
                    <AccountButton
                      onClick={toggleAccountOpen}
                      ref={accountButtonRef}
                      data-testid="account-dropdown-open"
                    >
                      <Account isOpen={accountOpen}>
                        <AliasBadge>
                          <Icon
                            name="shield-fill"
                            size="24"
                            color="blueberry400"
                          />
                          <Text weight="700" size="12">
                            {user.profile
                              ? user.profile.firstName.charAt(0)
                              : 'U'}
                          </Text>
                        </AliasBadge>
                        <Text
                          size={['24', null, '14']}
                          weight="700"
                          color="white400"
                        >
                          {user.profile ? user.profile.firstName : '-'}
                        </Text>
                      </Account>
                    </AccountButton>
                    {accountOpen && (
                      <AccountDropdown ref={accountDropdownRef}>
                        <DropdownNavList>
                          <li>
                            <DropdownNavLink
                              to="/account"
                              data-testid="account-link"
                            >
                              <Icon
                                name="player-profile-outline"
                                color="blackberry400"
                              />
                              <Text size="14">My Account</Text>
                            </DropdownNavLink>
                          </li>
                          <li>
                            <DropdownNavLink
                              to="/logout"
                              data-testid="logout-link"
                            >
                              <Icon name="log-out" color="blackberry400" />
                              <Text size="14">Log out</Text>
                            </DropdownNavLink>
                          </li>
                        </DropdownNavList>
                      </AccountDropdown>
                    )}
                  </AccountContainer>
                </ButtonContainer>
              ) : (
                <Stack gap="m" direction="row">
                  <Button
                    as={Link}
                    to="/login"
                    data-testid="global-login-link"
                    variant="white"
                    halo
                    size="small"
                    className="login-button"
                  >
                    Log in
                  </Button>
                  <Button
                    as={Link}
                    to="/signup"
                    data-testid="global-signup-link"
                    variant="white"
                    size="small"
                    className="signup-button"
                  >
                    Sign up
                  </Button>
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
        {searchOpen && (
          <Formik<SearchFormValues>
            initialValues={{
              query,
            }}
            onSubmit={values => {
              toggleSearchOpen()
              return navigate(`/search?query=${values.query}`, {
                replace: true,
              })
            }}
            isInitialValid={!!query}
            validationSchema={SearchFormValidationSchema}
          >
            {formProps => (
              <Form
                onSubmit={formProps.handleSubmit}
                action="/search"
                method="GET"
                ref={searchRef}
              >
                <FormContainer>
                  <SearchForm {...formProps} />
                </FormContainer>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </>
  )
}

export default GlobalHeader
